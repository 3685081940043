import { useState } from 'react'
import AllowBlockItem from '../AllowBlockItem'
import './style.scss'

const AllowBlockList = () => {
	const [isUploading, setIsuploading] = useState({ Pass: false, Block: false })

	return (
		<>
			<div
				className={`allowBlockWrapper ${
					isUploading.Pass || isUploading.Block ? 'disable-content' : ''
				}`}
			>
				<AllowBlockItem
					source='Pass'
					setIsuploading={setIsuploading}
					isUploading={isUploading}
					tooltipText={
						'Allow-list: The list of words that we explicitly want to allow. \
             Please Upload a file containing a list of words where each word should be placed in new line.'
					}
				/>
				<AllowBlockItem
					source='Block'
					setIsuploading={setIsuploading}
					isUploading={isUploading}
					tooltipText={
						'Block-list: The words we want to block or redact, block-list takes precedence above Allow-list. \
             Please Upload a file containing a list of words where each word should be placed in new line.'
					}
				/>
			</div>
		</>
	)
}

export default AllowBlockList

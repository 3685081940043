import PropTypes from 'prop-types'
import { ReactCompareSlider } from 'react-compare-slider'
import { useSelector } from 'react-redux'
import { REDACTION_VIEW } from '../../utilities/constants'

const MaksCompare = (props) => {
	const { imageData, fallbackImage, handleClick } = props
	return (
		<ReactCompareSlider
			onClick={(e) => handleClick(e)}
			position={95}
			onlyHandleDraggable={true}
			handle={
				<div className='updatedArrows'>
					<button className='alignSlider'>
						<div className='arrowBtn'>
							<div className=' arrow left'>
								<svg width='8px' height='10px' viewBox='0 0 50 80'>
									<polyline
										fill='none'
										stroke='#FFFFFF'
										strokeWidth='16'
										strokeLinecap='round'
										strokeLinejoin='round'
										points='45.63,75.8 0.375,38.087 45.63,0.375 '
									/>
								</svg>
							</div>
							<div className='arrow right'>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='8px'
									height='10px'
									viewBox='0 0 50 80'
								>
									<polyline
										fill='none'
										stroke='#FFFFFF'
										strokeWidth='16'
										strokeLinecap='round'
										strokeLinejoin='round'
										points='0.375,0.375 45.63,38.087 0.375,75.8 '
									/>
								</svg>
							</div>
						</div>
					</button>
				</div>
			}
			itemOne={
				<div className='fst-img-wrapper'>
					<img src={imageData.url} onError={(e) => fallbackImage(e)} alt='' />
				</div>
			}
			itemTwo={<img src={imageData.maskedUrl} onError={(e) => fallbackImage(e)} alt='' />}
			className='compareSlider'
		/>
	)
}

MaksCompare.propTypes = {
	imageData: PropTypes.object,
	fallbackImage: PropTypes.any,
	handleClick: PropTypes.func,
}

const RenderImage = ({ imageData, setImageText, imageText, ocrData, gettingData }) => {
	const { view } = useSelector((store) => store.storeProps)
	const handleClick = (e) => {
		try {
			document?.querySelector?.('.carousel') && document.querySelector('.carousel').focus()
			if (e && e.target && ocrData.length) {
				var childNodes = e.target.childNodes
				let imgNode
				let naturalWidth = 0
				let naturalHeight = 0
				if (childNodes.length) {
					imgNode = childNodes[0]
					naturalWidth = imgNode.naturalWidth
					naturalHeight = imgNode.naturalHeight
				}
				const imgBoundingBox = imgNode.getBoundingClientRect()
				const imgWidth = naturalWidth ? naturalWidth : 1000
				const imgHeight = naturalHeight ? naturalHeight : 500
				const imgX = e.pageX - imgBoundingBox.x
				const imgY = e.pageY - (imgBoundingBox.y + window.pageYOffset)
				let scaleImgLeft = imgX
				let scaleImgTop = imgY
				let bbWidth = 0
				let bbHeight = 0
				if (imgBoundingBox) {
					scaleImgLeft = Math.floor((imgWidth / imgBoundingBox.width) * imgX)
					scaleImgTop = Math.floor((imgHeight / imgBoundingBox.height) * imgY)
				}
				let clickedValue = ''
				let isNotExist = true
				let textCoordX = 0
				let textCoordY = 0
				isNotExist = ocrData.every((val) => {
					let coords =
						val && val.vertices && val.vertices.coordinates.length
							? val.vertices.coordinates[0]
							: []
					coords = coords.length ? coords[0] : []
					const coordX = coords.length ? coords[0] : 0
					const coordY = coords.length ? coords[1] : 0
					const textWidth = val.width + coordX
					const textHeight = val.height + coordY
					if (
						scaleImgLeft >= coordX &&
						scaleImgLeft <= textWidth &&
						scaleImgTop >= coordY &&
						scaleImgTop <= textHeight
					) {
						clickedValue = val.description
						bbWidth = val.width
						bbHeight = val.height
						textCoordX = coordX
						textCoordY = coordY
						return false
					}
					return true
				})
				if (isNotExist || !clickedValue) {
					setImageText({
						...imageText,
						status: true,
						bbStatus: false,
						text: '',
						message: 'No Result. Please try again.',
					})
				} else {
					setImageText({
						status: true,
						bbStatus: true,
						text: clickedValue,
						message: 'You clicked on this text:',
						top: Math.floor((imgBoundingBox.height / imgHeight) * textCoordY),
						left: Math.floor((imgBoundingBox.width / imgWidth) * textCoordX),
						width: Math.floor((imgBoundingBox.width / imgWidth) * bbWidth),
						height: Math.floor((imgBoundingBox.height / imgHeight) * bbHeight),
					})
				}
			} else {
				setImageText({
					...imageText,
					status: true,
					bbStatus: false,
					text: '',
					message: gettingData
						? 'Fetching OCR data. Please wait...'
						: 'OCR data is not available when masking is in progress.',
				})
			}
		} catch (err) {
			console.log(err)
		}
	}

	const fallbackImage = (e) => {
		e.target.src = './no-image.png'
	}

	if (view === 'masked' && imageData.maskedUrl !== '') {
		return (
			<MaksCompare imageData={imageData} handleClick={handleClick} fallbackImage={fallbackImage} />
		)
	} else if (view === REDACTION_VIEW && imageData.maskedUrl !== '') {
		return (
			<MaksCompare imageData={imageData} handleClick={handleClick} fallbackImage={fallbackImage} />
		)
	} else {
		return <img src={imageData.url} onError={(e) => fallbackImage(e)} alt='' />
	}
}

RenderImage.propTypes = {
	setImageText: PropTypes.func,
	imageText: PropTypes.object,
	ocrData: PropTypes.array,
	gettingData: PropTypes.bool,
	imageData: PropTypes.object,
}

export default RenderImage

import { REDACTION_VIEW } from '../../utilities/constants'
import {
	ADD_INDEX_VALUE,
	BULK_DELETE_PROPS,
	LOGOUT_ACTION,
	NO_RECORDS_AVAILABLE,
	REDACTIONVIEW,
	RE_REDACTION_STATUS,
	SAVE_ADMIN_USER_FLAG,
	SAVE_AREA_QUERY,
	SAVE_BUCKETS,
	SAVE_CONFIGURE_FIELD_STATUS,
	SAVE_DATE,
	SAVE_ENVARS,
	SAVE_FILE_CONFIGURATIONS,
	SAVE_FILTERS,
	SAVE_FILTER_FLAG,
	SAVE_GCP_FILENAME,
	SAVE_GRAPH_VISIBILITY,
	SAVE_IMAGE_DETAIL,
	SAVE_IMAGE_KEYS,
	SAVE_IMAGE_LOAD_COUNT,
	SAVE_IMG_URL,
	SAVE_MAPPING_KEY,
	SAVE_MAPPING_PROPS,
	SAVE_MASKED_BUCKET,
	SAVE_MASKED_FLAG,
	SAVE_NAME,
	SAVE_QUERY_STACK,
	SAVE_ROUTE_LOCATION,
	SAVE_SCREEN_SIGNATURE,
	SAVE_SEARCH_DATA,
	SAVE_SELECTED_BUCKET,
	SAVE_SESSION_FLAG,
	SAVE_SLOTS,
	SAVE_TOKEN_FLAG,
	SET_DELETE_MODE,
	SET_DELETE_RECORD_COUNT,
	SET_ENABLE_SEARCH,
	SET_VIEW,
	UPDATE_CONFIGURE_FIELDS,
	UPDATE_ENVARS,
} from '../types'

export const initialState = {
	indexValue: '',
	allIndexes: '',
	indexAlias: '',
	aliasIndexesArr: [],
	aliasFlag: false,
	searchData: [],
	imagesUrls: [],
	filters: '',
	observedAtDetail: '',
	mappingImageKey: '',
	screenSignature: '',
	imageLoadCount: 25,
	configuredFields: [{ label: 'title' }],
	areaQuery: '',
	isConfigureFieldEnable: true,
	updateEnvars: false,
	gcpFileName: '',
	buckets: '',
	selectedBucket: '',
	envars: '',
	carouselSlots: ['1'],
	imageKeys: [],
	fileConfigurations: '',
	imageDetail: [],
	maskedBucket: '',
	view: 'carouselView',
	mappingProps: '',
	queryStack: [],
	graphVisibility: false,
	maskedFlag: false,
	tokenFlag: false,
	username: '',
	routeLocation: '',
	sessionFlag: false,
	adminUserFlag: false,
	versionConflict: { flag: false },
	showFilterFlag: false,
	isDeleteFilterFlag: false,
	isDeleteInProgressFlag: false,
	isDeleteModeOn: false,
	deleteState: '',
	deleteUUID: '',
	deleteRecordCount: 0,
	noRecordsAvailable: true,
	isDeleteSliderShow: false,
	reRedactionStatus: false,
	redactionView: REDACTION_VIEW,
}

// eslint-disable-next-line
export default (state = initialState, action) => {
	const obj = {
		...state,
		...action.payload,
	}
	switch (action.type) {
		case ADD_INDEX_VALUE: {
			return obj
		}
		case SAVE_SEARCH_DATA: {
			return obj
		}
		case SAVE_IMG_URL: {
			return obj
		}
		case SAVE_FILTERS: {
			return obj
		}
		case SAVE_DATE: {
			return obj
		}
		case SAVE_MAPPING_KEY: {
			return obj
		}
		case SAVE_IMAGE_LOAD_COUNT: {
			return obj
		}
		case SAVE_SCREEN_SIGNATURE: {
			return obj
		}
		case UPDATE_CONFIGURE_FIELDS: {
			return obj
		}
		case SAVE_AREA_QUERY: {
			return obj
		}
		case SAVE_CONFIGURE_FIELD_STATUS: {
			return obj
		}
		case UPDATE_ENVARS: {
			return obj
		}
		case SAVE_GCP_FILENAME: {
			return obj
		}
		case SAVE_BUCKETS: {
			return obj
		}
		case SAVE_SELECTED_BUCKET: {
			return obj
		}
		case SAVE_ENVARS: {
			return obj
		}
		case SAVE_SLOTS: {
			return obj
		}
		case SAVE_IMAGE_KEYS: {
			return obj
		}
		case SAVE_FILE_CONFIGURATIONS: {
			return obj
		}
		case SAVE_IMAGE_DETAIL: {
			return obj
		}
		case SAVE_MASKED_BUCKET: {
			return obj
		}
		case SET_VIEW: {
			return {
				...state,
				view: action.payload,
			}
		}
		case SAVE_MAPPING_PROPS: {
			return obj
		}
		case SAVE_QUERY_STACK: {
			return obj
		}
		case SAVE_GRAPH_VISIBILITY: {
			return obj
		}
		case SET_ENABLE_SEARCH: {
			return obj
		}
		case SAVE_MASKED_FLAG: {
			return obj
		}
		case SAVE_TOKEN_FLAG: {
			return obj
		}
		case SAVE_NAME: {
			return obj
		}
		case SAVE_ROUTE_LOCATION: {
			return obj
		}
		case SAVE_SESSION_FLAG: {
			return obj
		}
		case SAVE_ADMIN_USER_FLAG: {
			return obj
		}
		case LOGOUT_ACTION: {
			return initialState
		}
		case SAVE_FILTER_FLAG: {
			return obj
		}
		case SET_DELETE_MODE: {
			return obj
		}
		case BULK_DELETE_PROPS: {
			return obj
		}
		case SET_DELETE_RECORD_COUNT: {
			return obj
		}
		case NO_RECORDS_AVAILABLE: {
			return obj
		}
		case RE_REDACTION_STATUS: {
			return obj
		}
		case REDACTIONVIEW: {
			return obj
		}
		default:
			return state
	}
}

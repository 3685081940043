import { InfoCircleFilled } from '@ant-design/icons'
import { Form } from 'antd'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import BadgeComponent from '../BadgeComponent'
import CustomButton from '../Button'
import customTost from '../Notification'
import './style.scss'

// find duplicate and return unique and dulicated items
const findDuplicates = (arr) => {
	arr = arr.map((i) => i.toLowerCase())
	const uniq = [...new Set(arr)]

	// original array won't be modified)
	const dup = arr.filter((e, i, a) => a.indexOf(e) !== i)

	return { uniq, dup }
}

const AddWords = (props) => {
	const {
		getAndUpdateFile,
		loading,
		list,
		setList,
		listText,
		setListText,
		duplicateItems,
		setDuplicateItems,
		source,
	} = props
	const [words, setWords] = useState('')

	useEffect(() => {
		parseString()
		// eslint-disable-next-line
	}, [list])

	const parseString = () => {
		let result = list.map((item) => `"${item.label}"`)
		let str = result.toString()
		setWords(str)
	}

	const checkAlltheLis = (wordsList, words) => {
		// make a string of array from object of array
		const mergedList = wordsList.map((i) => i.label)
		// get duplicate and unique values from secound list and typed words
		const compareWithList = findDuplicates([...words, ...mergedList])

		if (compareWithList.dup.length) {
			// add values, if there are duplicates in first list
			addList(compareWithList.uniq, compareWithList.dup.join(' '))
			setDuplicateItems(compareWithList.dup)
			return { error: '' }
		}

		setDuplicateItems([])
		addList(words)
		return { words }
	}

	const addFormatedData = (e) => {
		try {
			e.preventDefault()
			const disabledOn = e.relatedTarget
			if (disabledOn?.classList.contains('delete-form')) return

			let texts = listText.split(',').join(' ').split('\t').join(' ').trim().split(' ')
			texts = texts.filter((i) => i)

			if (!texts.length) {
				setDuplicateItems([])
				return
			}

			const outer = checkAlltheLis(list, texts)
			if (!outer.words) {
				customTost({
					type: 'error',
					message: 'Duplicate word not allowed',
				})
				return
			}
		} catch (err) {
			console.log('Error [AddWords addFormatedData]: ', err)
		}
	}

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			addFormatedData(e)
		}
	}

	const deleteList = (val) => {
		let filtered = list.filter((item) => item.id !== val.id)
		if (val.label === duplicateItems) {
			setDuplicateItems([])
		}
		setList(filtered)
	}

	const addList = (newList, lisText = '') => {
		if (newList.length > 0) {
			newList = newList.filter((str) => /\S/.test(str))
			let arr = newList.map((item) => {
				return { label: item.trim(), id: Math.random() }
			})
			let updatedList = arr

			if (!lisText) {
				updatedList = [...list, ...arr]
			}
			setList(updatedList)
			setListText(lisText)
		}
	}

	const handleChange = (e) => {
		const val = e.target.value
		const result = val.split(/\r?\n/)
		let str = result.toString()
		setListText(str)
	}

	return (
		<Form.Item
			label={<>Or press enter and add new words below to update the existing document</>}
			tooltip={{
				title: `Type a word, press Enter. Similarly add more words and finally Click UPDATE button to add the word(s) in existing ${source} list.`,
				icon: <InfoCircleFilled />,
			}}
		>
			<div
				id={`words_wrapper_${source}`}
				className={`words_wrapper ${!duplicateItems.length ? '' : 'highlight-box'}`}
			>
				{list.length > 0 ? (
					<BadgeComponent
						list={list}
						deleteItem={(item) => {
							deleteList(item)
						}}
						duplicateItems={duplicateItems}
					/>
				) : (
					<BadgeComponent list={[]} />
				)}
				<div className='grow-wrap'>
					<textarea
						value={listText}
						onBlur={addFormatedData}
						onKeyDown={handleKeyDown}
						onChange={handleChange}
						className='redaction_textarea'
					/>
				</div>
				<div className='btn_add'>
					<CustomButton
						className='clear-btn'
						isDisabled={!list.length || loading}
						onClick={() => {
							setListText('')
							setList([])
							setDuplicateItems([])
						}}
					>
						{'CLEAR ALL'}
					</CustomButton>

					<CustomButton
						className='updateList'
						type='primary'
						isDisabled={!list.length}
						onClick={() => getAndUpdateFile(words, 'Add')}
						isLoading={loading}
					>
						{'UPDATE'}
					</CustomButton>
				</div>
			</div>
		</Form.Item>
	)
}

AddWords.propTypes = {
	getAndUpdateFile: PropTypes.func,
	loading: PropTypes.bool,
	list: PropTypes.array,
	setList: PropTypes.func,
	listText: PropTypes.string,
	setListText: PropTypes.func,
	duplicateItems: PropTypes.array,
	setDuplicateItems: PropTypes.func,
	source: PropTypes.string,
}

export default AddWords

import { EyeInvisibleOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Radio, Select, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import Text from 'antd/lib/typography/Text';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import ErrorIcon from '../../../Images/form-error.svg';
import { REDACTION_API, createMC } from '../../../services';
import { SearchOutLined } from '../../../utilities/IconSets';
import CustomButton from '../../Button';
import customTost from '../../Notification';
import { getSignedUrl } from '../redaction';
import RegexModel from './regexModel';
import RegexTable from './regexTable';
import './styles.scss';

const { Option } = Select;
const DESCRIPTION = 'description';

export const InfoNote = (n) => (
	<div className={`info-wrapper ${n.className}`}>
		<InfoCircleOutlined />
		<span>{n.text}</span>
	</div>
);

// A function that checks if a regex pattern is valid and non-empty
const isValidRegex = (pattern) => {
	if (pattern?.trim() === '') {
		// If yes, return false
		return false;
	}
	// Otherwise, try to create a new RegExp object with the given pattern
	try {
		new RegExp(pattern);
	} catch (e) {
		// If an error is thrown, the pattern is invalid
		return false;
	}
	return true;
};

// custom error component
const CustomError = (p) => {
	if (!p.err?.error) {
		return null;
	}

	return (
		<div className='regex-error'>
			<div className='err-wrapper'>
				<img src={ErrorIcon} alt='err-icon' className='err-icon' />
				<span>{p.err.error} </span>
			</div>
			<span>{p.err?.title}</span>
		</div>
	);
};

const formatErrData = (arr, type, data) => {
	return arr.map((i) =>
		i.type === type
			? {
					...i,
					state: data.state,
					error: data.error,
					title: data.title,
			  }
			: i,
	);
};

const RegexRedaction = (props) => {
	const [form] = Form.useForm();
	const { envars, adminUserFlag } = useSelector((store) => store.storeProps);
	const minioClient = createMC(envars);
	const { getSelectedImg, handleRedactionProcess, previewView, setPreviewView, setPreviewLoading } =
		props;
	const [formValues, setFormValues] = useState({});
	const [regexData, setRegexData] = useState([]);
	const [filteredRegexData, setFilteredRegexData] = useState([]);
	const [isModalOpen, setIsModelOpen] = useState(false);
	const [previewDisable, setPreviewDisable] = useState(true);
	const [commitDisable, setCommitDisable] = useState(true);
	const [regexFileData, setRegexFileData] = useState({});
	const [editView, setEditView] = useState({ state: false, data: '' });
	const [isLoading, setLoading] = useState(false);
	const [deleteView, setDeleteView] = useState({ state: false, data: '' });
	const [searchField, setSearchField] = useState(DESCRIPTION);
	const [searchValue, setSearchValue] = useState({ value: '', type: '' });
	const [showRegexForm, setShowRegexForm] = useState(false);
	const [fieldErrs, setFieldErrs] = useState([
		{ state: false, type: 'regex' },
		{ state: false, type: 'des' },
	]);
	const descriptionSuffix =
		searchValue?.value?.length === 0 ? (
			<Tooltip title='Extra information'>
				<SearchOutLined />
			</Tooltip>
		) : (
			<span />
		);

	useEffect(() => {
		getRegexData();
		setPreviewView({ state: false, data: '', singlePreview: false });
	}, []);

	useEffect(() => {
		setPreviewDisable(
			!fieldErrs[0]?.state && !fieldErrs[1]?.state && !fieldErrs[2]?.state ? false : true,
		);
	}, [fieldErrs]);

	const matchVal = (regexData, newVal, key) => {
		if (regexData.length) {
			return regexData.find((val) => val[key]?.toLowerCase() === newVal);
		}
	};

	const handleValuesChange = async (changedValues, allValues) => {
		setCommitDisable(true);
		let isRegexExist = matchVal(regexFileData, allValues?.regex?.trim().toLowerCase(), 'regex');
		let isDesExist = matchVal(
			regexFileData,
			allValues?.description?.trim().toLowerCase(),
			'description',
		);

		if (editView?.state) {
			isRegexExist = changedValues['regex']
				? allValues?.regex?.trim().toLowerCase() === editView?.data?.regex?.trim().toLowerCase()
					? false
					: isRegexExist
				: false;
			isDesExist = changedValues['description']
				? allValues?.description?.trim().toLowerCase() ===
				  editView?.data?.description?.trim().toLowerCase()
					? false
					: isDesExist
				: false;
		}

		let err = [
			{
				type: 'regex',
				state: !allValues?.regex ? true : isRegexExist ? true : false,
				error: isRegexExist ? `This regex already exists.` : '',
				title: isRegexExist ? 'Check the table to find the existing regex' : '',
			},
			{
				type: 'des',
				state: !allValues?.description ? true : isDesExist ? true : false,
				error: isDesExist ? `This description already exists.` : '',
				title: isDesExist ? 'Check the table to find the existing description' : '',
			},
		];
		setFieldErrs(err);
		if (previewView?.data) {
			setPreviewView({ state: false, data: '', singlePreview: false });
		}
	};

	useEffect(() => {
		if (previewView?.state) {
			onSubmit(previewView?.data);
		}
	}, [previewView]);

	const getRegexData = () => {
		const chunks = [];
		let fileString = '';
		/* istanbul ignore next */
		try {
			minioClient.getObject('dictionaries', 'regex.json', function (err, dataStream) {
				if (err) {
					return console.log(err);
				}
				dataStream.on('data', function (chunk) {
					chunks.push(Buffer.from(chunk));
				});
				dataStream.on('end', function () {
					fileString = Buffer.concat(chunks).toString('utf-8');
					const parseObj = JSON.parse(fileString);
					setRegexFileData(parseObj);
					setRegexData(parseObj);
				});
				dataStream.on('error', function (err) {
					console.log(err);
				});
			});
		} catch (err) {
			/* tslint:disable:no-empty */
		}
	};

	const updateRegexData = (fileStream, action) => {
		/* istanbul ignore next */
		try {
			minioClient.putObject(
				'dictionaries',
				'regex.json',
				JSON.stringify(fileStream),
				function (err) {
					if (err) return;
					getRegexData();
					action === 'delete' ? setDeleteView({ state: false, data: '' }) : handleCancel();
					setLoading(false);
					setPreviewLoading(false);
					handleRedactionProcess('');
					customTost({
						type: 'success',
						message: `${action === 'delete' ? 'Deleted' : 'Commit'} successfully.`,
					});
				},
			);
		} catch (err) {
			/* tslint:disable:no-empty */
		}
	};

	const onSubmit = async (values) => {
		try {
			setPreviewLoading(true);
			const newRegexObj =
				'id' in values
					? values
					: {
							id: `${values?.type}_${new Date().getTime()}`,
							key: `${values?.type}_${new Date().getTime()}`, // for table key
							source: 'custom_regex',
							description: values.description.trim(),
							regex: values.regex.trim(),
							type: values.type,
					  };
			setFormValues(newRegexObj);
			setSearchField(DESCRIPTION);
			setSearchValue({ value: '', type: '' });
			const imgId = getSelectedImg();
			const imgKey = imgId.split('.');
			let payload = {
				UUID: imgKey[0],
				custom_regex: [newRegexObj],
			};
			if (editView?.state) {
				payload = { ...payload, edit_regex: editView?.data?.id };
			}
			if (previewView?.state && previewView?.singlePreview) {
				payload = { ...payload, preview: true };
			}
			const res = await REDACTION_API(payload);
			const { data } = res;
			if (res.status === 200) {
				const maskedImg = await getSignedUrl(envars, `${imgKey[0]}_redacted.${imgKey[1]}`);
				handleRedactionProcess(maskedImg);
				setCommitDisable(adminUserFlag ? false : true);
				customTost({
					type: 'success',
					message: data?.message ? data.message : 'Redacted Successfully!.',
				});
				setPreviewLoading(false);
				setPreviewView({
					state: false,
					data: previewView?.data ? previewView?.data : newRegexObj,
					singlePreview: previewView?.singlePreview,
				});
				document?.querySelector?.('.carousel') && document.querySelector('.carousel').focus();
			}
		} catch (err) {
			setPreviewLoading(false);
			setPreviewView({ state: false, data: '', singlePreview: false });
			customTost({
				type: 'error',
				message: err?.response?.data?.message
					? err?.response?.data?.message
					: 'Internal server error.',
			});
			handleRedactionProcess('');
			setPreviewLoading(false);
		}
	};

	const handleCancel = () => {
		setShowRegexForm(false);
		form.resetFields();
		setFormValues({});
		setCommitDisable(true);
		setPreviewDisable(true);
		setEditView({ state: false, data: '' });
		setPreviewView({ state: false, data: '', singlePreview: false });
		setFieldErrs([
			{ state: false, type: 'regex' },
			{ state: false, type: 'des' },
		]);
		handleRedactionProcess('');
		setSearchField(DESCRIPTION);
		setSearchValue({ value: '', type: '' });
	};

	return (
		<>
			<div className='regex-container'>
				{showRegexForm ? (
					<div>
						<div className='regex-form-header'>
							<Text>{editView?.state ? 'Edit' : 'Create'} regex expression</Text>
						</div>

						{fieldErrs.map((val) => {
							return <CustomError err={val} key={val?.type} />;
						})}
						<Form
							initialValues={{ type: 'block' }}
							layout='vertical'
							onFinish={onSubmit}
							onValuesChange={handleValuesChange}
							form={form}
							requiredMark={false}
							className='regex-form'
						>
							<Form.Item
								name='regex'
								className='form-item'
								label={<>Regex expression</>}
								rules={[
									{
										validator: (_, value) => {
											const isValid = isValidRegex(value);

											if (isValid) {
												return Promise.resolve();
											}
											const err = formatErrData(fieldErrs, 'regex', {
												state: true,
												error: 'Invalid regular expression',
												title: 'Correct the expression and try again',
											});
											return Promise.reject(setFieldErrs(err));
										},
									},
								]}
							>
								<Input placeholder='[abc]\g' className='regex-input' />
							</Form.Item>

							<Form.Item
								className='form-item'
								name='description'
								label={<>Description</>}
								rules={[
									{
										min: 5,
										message: () => {
											setFieldErrs(() =>
												formatErrData(fieldErrs, 'des', {
													state: true,
													error: 'Description field',
													title: 'Please enter at least 5 characters',
												}),
											);
										},
									},
									{
										max: 100,
										message: () => {
											setFieldErrs(() =>
												formatErrData(fieldErrs, 'des', {
													state: true,
													error: 'Description field',
													title: ' Maximum limit is 100 characters',
												}),
											);
										},
									},
								]}
							>
								<TextArea placeholder='Add description' className='regex-input regex-des' />
							</Form.Item>

							<Form.Item className='form-item' name='type' label={<>Action</>}>
								<Radio.Group className='checkbox-wrapper'>
									<Radio value={'pass'}>Allow</Radio>
									<Radio value={'block'}>Block</Radio>
								</Radio.Group>
							</Form.Item>

							<div className='btn-container'>
								<CustomButton onClick={handleCancel}>Cancel</CustomButton>
								<CustomButton
									className={'r-preview'}
									type='primary'
									htmlType='submit'
									isDisabled={previewDisable}
								>
									Preview
								</CustomButton>

								<CustomButton
									type='primary'
									onClick={() => setIsModelOpen(true)}
									isDisabled={commitDisable}
								>
									Commit
								</CustomButton>
							</div>
							<InfoNote
								text={
									'Redaction results are from all configured regex, including the current regex'
								}
							/>
						</Form>
					</div>
				) : (
					<>
						<div className='regex-header'>
							<Select
								defaultValue='description'
								className='select-before'
								bordered={false}
								onChange={(e) => {
									setSearchField(e);
									setSearchValue({ value: '', type: '' });
								}}
							>
								<Option value={DESCRIPTION}>Description</Option>
								<Option value='action'>Action</Option>
							</Select>
							{searchField === DESCRIPTION ? (
								<Input
									placeholder='Search'
									bordered={false}
									suffix={descriptionSuffix}
									onChange={(e) => {
										setSearchValue({ value: e?.target?.value, type: 'desc' });
										setFilteredRegexData(
											regexData.filter((value) =>
												value.description.toLowerCase().includes(e.target.value.toLowerCase()),
											),
										);
									}}
								/>
							) : (
								<Select
									allowClear
									bordered={false}
									onClear={() => {
										setSearchValue({ value: '', type: '' });
										setFilteredRegexData(regexData);
									}}
									className='select-before'
									placeholder='Select action'
									onChange={(e) => {
										setSearchValue({ value: e, type: 'action' });
										setFilteredRegexData(regexData.filter((value) => value.type === e));
									}}
								>
									<Option value='pass'>Allow</Option>
									<Option value='block'>Block</Option>
								</Select>
							)}
						</div>
						<div className='expression-wrapper'>
							<Text className='expr-text'>Expressions</Text>
							<div className='expression-icons'>
								{previewView?.data && (
									<Tooltip title='Disable Preview View'>
										<EyeInvisibleOutlined
											className='dis-icon'
											onClick={() => {
												setPreviewView({ state: false, data: '', singlePreview: false });
												handleRedactionProcess('');
											}}
										/>
									</Tooltip>
								)}

								<Tooltip title='Create regex'>
									<PlusOutlined
										onClick={() => {
											setPreviewDisable(true);
											setShowRegexForm(true);
											setPreviewView({ state: false, data: '', singlePreview: false });
											handleRedactionProcess('');
										}}
									/>
								</Tooltip>
							</div>
						</div>
						<RegexTable
							regexData={searchValue.value ? filteredRegexData : regexData}
							form={form}
							setEditView={setEditView}
							setPreviewView={setPreviewView}
							isLoading={isLoading}
							setShowRegexForm={setShowRegexForm}
							setPreviewDisable={setPreviewDisable}
							handleRedactionProcess={handleRedactionProcess}
							setCommitDisable={setCommitDisable}
							setIsModelOpen={setIsModelOpen}
							setDeleteView={setDeleteView}
						/>
						<InfoNote
							className='regex-info'
							text={'Redaction results are from the current regex only'}
						/>
					</>
				)}

				<RegexModel
					isModalOpen={isModalOpen}
					setIsModelOpen={setIsModelOpen}
					deleteView={deleteView}
					setLoading={setLoading}
					setPreviewLoading={setPreviewLoading}
					regexFileData={regexFileData}
					updateRegexData={updateRegexData}
					editView={editView}
					formValues={formValues}
				/>
			</div>
		</>
	);
};

RegexRedaction.propTypes = {
	isLocLoading: PropTypes.bool,
	getSelectedImg: PropTypes.func,
	handleRedactionProcess: PropTypes.func,
	setPreviewView: PropTypes.func,
	previewView: PropTypes.object,
	setPreviewLoading: PropTypes.func,
};

export default RegexRedaction;

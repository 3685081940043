import { Tabs } from 'antd'
import PropTypes from 'prop-types'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import AllowBlockList from '../AllowBlockList'
import Forwarding from '../Forwarding'
import RedactionTab from './RedactionTab'

const { TabPane } = Tabs

const SettingsTab = (props) => {
	const {
		isdeploying,
		isDisabled,
		isProxySelected,
		initialValues,
		pegEnvars,
		setPegEnvars,
		setProxySelected,
	} = props
	const [disableRedactionTab, setDisableRedactionTab] = useState(false)
	const [disableForwardTab, setDisableForwardTab] = useState(false)
	const { reRedactionStatus } = useSelector((store) => store.storeProps)
	const operations = (
		<div className='disableMessage'>
			{disableForwardTab
				? 'The forwarding tab is disabled until UPDATE is in progress.'
				: 'The redaction list tab is disabled until DEPLOY/RE-REDACTION is in progress.'}
		</div>
	)
	return (
		<Tabs
			defaultActiveKey='1'
			className='peg_tabs'
			tabBarExtraContent={
				disableRedactionTab || disableForwardTab ? (reRedactionStatus ? '' : operations) : ''
			}
		>
			<TabPane tab='Redaction List' key='1' disabled={disableRedactionTab}>
				<AllowBlockList />
				<RedactionTab
					setDisableForwardTab={setDisableForwardTab}
					isdeploying={isdeploying}
					isDisabled={isDisabled}
					initialValues={initialValues}
					pegEnvars={pegEnvars}
					setPegEnvars={setPegEnvars}
				/>
			</TabPane>
			<TabPane tab='Forwarding' key='2' disabled={disableForwardTab}>
				<Forwarding
					setDisableRedactionTab={setDisableRedactionTab}
					isDisabled={isDisabled}
					isProxySelected={isProxySelected}
					setProxySelected={setProxySelected}
					initialValues={initialValues}
					pegEnvars={pegEnvars}
					setPegEnvars={setPegEnvars}
				/>
			</TabPane>
		</Tabs>
	)
}
SettingsTab.propTypes = {
	setActiveTab: PropTypes.func,
	form: PropTypes.object,
	isdeploying: PropTypes.bool,
	setIsDeploying: PropTypes.func,
	redactionLoading: PropTypes.bool,
	SetRedactionLoading: PropTypes.func,
	isDisabled: PropTypes.bool,
	isProxySelected: PropTypes.bool,
	setUseProxy: PropTypes.func,
	updateConfig: PropTypes.func,
	initialValues: PropTypes.object,
	pegEnvars: PropTypes.array,
	setPegEnvars: PropTypes.func,
	setProxySelected: PropTypes.func,
}

export default SettingsTab

import { CheckCircleFilled, InfoCircleFilled } from '@ant-design/icons'
import { Form, Select } from 'antd'
import axios from 'axios'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import ROOT from '../../../client'
import CustomButton from '../../Button'
import CustomModal from '../../Modal'
import customTost from '../../Notification'
import './styles.scss'

const { Option } = Select

const RedactionTab = ({
	isDisabled,
	pegEnvars,
	setPegEnvars,
	initialValues,
	setDisableForwardTab,
}) => {
	const [form] = Form.useForm()
	const [isdeploying, setIsDeploying] = useState(false)
	const [pegDeploySuccess, setPegDeploySuccess] = useState(false)

	useEffect(() => {
		if (initialValues) {
			form.setFieldsValue(initialValues)
		}
	}, [initialValues])

	const handleClose = () => {
		setPegDeploySuccess(false)
	}

	const checkKeyDown = (e) => {
		if (e.code === 'Enter') e.preventDefault()
	}

	const onSubmit = async (values) => {
		try {
			setIsDeploying(true)
			setDisableForwardTab(true)
			if (values?.symspell__edit_distance) {
				values.symspell__edit_distance = values.symspell__edit_distance === 'true' ? '1' : '0'
			}

			const updatedVars = pegEnvars.map((obj) => {
				if (obj.name in values === true) {
					const newVal = {
						name: obj.name,
						value: values[obj.name],
					}
					return newVal
				} else {
					return obj
				}
			})
			setPegEnvars(updatedVars)
			const res = await axios.post(`${ROOT}/api/update-deployment-envars`, {
				updatedVars,
				flag: true,
			})

			if (res.status === 200) {
				setPegDeploySuccess(true)
			}

			setIsDeploying(false)
			setDisableForwardTab(false)
		} catch (err) {
			console.log('Error [RedactionTab]: ', err)
			setIsDeploying(false)
			setDisableForwardTab(false)
			customTost({
				type: 'error',
				message: err?.response?.data?.message
					? err.response.data.message
					: 'Internal server error.',
			})
		}
	}

	return (
		<>
			<div className='redaction-header'>
				<span>Redaction Word Filter Setting</span>
				<div className='saprater' />
			</div>
			<div className={'redaction-wrapper'}>
				<div className='redaction-form'>
					<Form
						layout='vertical'
						onFinish={onSubmit}
						form={form}
						requiredMark={false}
						onKeyDown={(e) => checkKeyDown(e)}
						className='redactionConfigForm'
					>
						<div className='dual-row'>
							<Form.Item
								className='form-field'
								label={<>Fuzzy Matching</>}
								name='symspell__edit_distance'
								tooltip={{
									title: 'Disable exact match',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select>
									<Option value='true'>true</Option>
									<Option value='false'>false</Option>
								</Select>
							</Form.Item>
							<Form.Item
								className='form-field'
								label={<>Cleaning</>}
								name='cleaning'
								tooltip={{
									title:
										'If set to true, PEG will remove all non-alphabet characters from a detected word before comparing it to the words in the dictionaries.',
									icon: <InfoCircleFilled />,
								}}
							>
								<Select>
									<Option value='true'>true</Option>
									<Option value='false'>false</Option>
								</Select>
							</Form.Item>
						</div>

						<div className='btn-wrapper'>
							<CustomButton
								type='primary'
								htmlType='submit'
								isLoading={isdeploying}
								isDisabled={isDisabled}
							>
								{'UPDATE'}
							</CustomButton>
						</div>
					</Form>
				</div>
			</div>

			<CustomModal
				isOpen={pegDeploySuccess}
				className='successModal modal_wrapper'
				onClose={handleClose}
				footer={[
					<CustomButton onClick={handleClose} type='primary' key='btnClose' id='btnClose'>
						{'CLOSE'}
					</CustomButton>,
				]}
			>
				<div className='success_modal'>
					<CheckCircleFilled style={{ color: '#03A803', fontSize: '50px' }} />
					<div className='success_modal_header'>Deployed Successfully</div>
				</div>
			</CustomModal>
		</>
	)
}

RedactionTab.propTypes = {
	isdeploying: PropTypes.bool,
	isDisabled: PropTypes.bool,
	updateConfig: PropTypes.func,
	initialValues: PropTypes.object,
	pegEnvars: PropTypes.array,
	setPegEnvars: PropTypes.func,
	setDisableForwardTab: PropTypes.func,
}

export default RedactionTab
